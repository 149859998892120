import React from 'react';
import { Grid, CircularProgress, Divider } from '@mui/material';
import { useSelector } from 'react-redux';

import Post from './Post/Post'
//import useStyles from './styles'; // unused

const Posts = ({ posts, isLoading, setCurrentId }) => {
    //const { posts, isLoading } = useSelector((state) => state.posts); // props added to Home and UserProfile so we can show the posts of the concerned state
    //const classes = useStyles(); // unused

    if(!posts.length && !isLoading) return 'No posts yet';
    
    return (
        isLoading ? <CircularProgress /> : (
            <Grid container alignItems="stretch" spacing={4}>
                {posts.map((post) => (
                    <Grid key={post._id} item xs={12} sm={12} md={12} lg={12}>
                        <Post post={post} setCurrentId={setCurrentId}/>
                        <Divider sx={{borderColor: '#eeeeee'}}/>
                    </Grid>
                ))}
            </Grid>
        )
    );
}
// !posts.length ? <CircularProgress /> : if 0 posts, circular progress turning - if >0, show the posts
//xs: size on mobile, sm: size on small-medium devices, md medium, lg large (12 divided by something)

export default Posts;