import React, { useState } from 'react';
import { Avatar, Button, Paper, Grid, Typography, Container } from '@mui/material';
import { GoogleLogin } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Input from './Input';
import Icon from './icon';
import { signin, signup, signinGoogleUser } from '../../actions/auth';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledContainer from './authStyles';

const theme = createTheme();
const PREFIX = 'Auth';

const classes = {
    paper: `${PREFIX}-paper`,
    root: `${PREFIX}-root`,
    avatar: `${PREFIX}-avatar`,
    form: `${PREFIX}-form`,
    submit: `${PREFIX}-submit`,
    googleButton: `${PREFIX}-googleButton`,
    swithModeButton: `${PREFIX}-swithModeButton`
};


const initialState = { userName: '', email: '', password: '', confirmPassword: ''  };  // initial state of the form data

const Auth = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [isSignup, setIsSignup] = useState(false);
    const [formData, setFormData] = useState(initialState);
    const dispatch = useDispatch();
    const history = useHistory();

    const handleShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);

    const handleSubmit = (e) => {
        e.preventDefault(); // to avoid refreshing the page, "always do that with React forms, we always prefer to have smth on the screen"

        if(isSignup) {  // logic to sign up the user
            dispatch(signup(formData, history)) // history to navigate when something happens
        } else {  // logic to sign in the user
            dispatch(signin(formData, history))
        }
    };

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value }); // when manual auth, make sure to spread all of the others properties, but only change the specific one we are currently on with the target value (current input) + avoid changing each state field separately, which is quite a lot of code ; it is dynamic and works for infinite number of fields
    };

    const switchMode = () => {
        setIsSignup((prevIsSignup) => !prevIsSignup);
        setShowPassword(false);
    };

    const googleSuccess = async (res) => {
        const result = res?.profileObj; //?. : "optional chaining" : no error if res doesn't exist
        const token = res?.tokenId; // useless since we create our own token with signinGoogleUser

        try {
            dispatch(signinGoogleUser(result, history)) // sign in user with our own token
        } catch (error) {
            console.log(error);
        }
    };

    const googleFailure = (error) => {
        console.log(error);
        console.log("Google Sign In was unsuccessful. Try again later.");
    };
    
    return (
        <Grid >
        <StyledContainer component="main" maxWidth="xs" >
            <Paper className={classes.paper} elevation={0}>
                <Typography component="h1" variant="h6" marginTop="20px" fontWeight='bold'>{isSignup ? "Join circletree." : 'Welcome back.'}</Typography>
                <form className={classes.form} onSubmit={handleSubmit}>
                    <Grid container spacing={1} marginBottom="-5px">
                        { isSignup && (
                        <>
                            <Input name="userName" label="Username" variant="outlined" handleChange={handleChange} type="name" />
                        </>
                        )}
                        <Input name="email" label="Email address" handleChange={handleChange} type="email" />
                        <Input name="password" label="Password" handleChange={handleChange} type={showPassword ? "text" : "password"} handleShowPassword={handleShowPassword} />
                        { isSignup && <Input name="confirmPassword" label="Confirm password" handleChange={handleChange} type="password" /> }
                    </Grid>
                    <Button className={classes.submit} type="submit" fullWidth
                        sx={{ color: 'white', borderColor: 'black' }}
                        variant="contained" size="small" disableElevation
                    // color="primary"
                    >
                    {isSignup ? "Sign up" : "Log in"}
                    </Button>
                    <GoogleLogin 
                        clientId="126982828412-vi02knabv1a10ra0hq4m6gqrrkmj37p5.apps.googleusercontent.com"
                        render={(renderProps) => (
                            <Button className={classes.googleButton} fullWidth //color="primary"
                            sx={{ color: 'white', backgroundColor: 'black', borderColor: 'black' }} size="small"
                            onClick={renderProps.onClick} disabled={renderProps.disabled} startIcon={<Icon/>}
                            variant="contained" disableElevation
                            >
                                {isSignup ? "Sign up with Google" : "Log in with Google"}
                            </Button>
                        )}
                        onSuccess={googleSuccess}
                        onFailure={googleFailure}
                        cookiePolicy="single_host_origin"
                    /> 

                    <Grid container justifyContent="flex-end">
                        <Grid item margin='5px 0px -10px 0px'>
                            <Button className={classes.swithModeButton} onClick={switchMode} 
                            sx={{ color: 'black', borderColor: 'black' }} size="small"
                            >
                                { isSignup ? "Already have an account? Log in" : "No account yet? Create one" }
                            </Button>
                        </Grid>

                    </Grid>
                </form>
            </Paper>
        </StyledContainer>
        </Grid>
    );
};

export default Auth;