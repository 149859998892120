import { styled } from '@mui/material/styles';
import { Grow } from '@mui/material';

const PREFIX = 'UserProfile';

const classes = {
    profileHead: `${PREFIX}-profileHead`,
    pagination: `${PREFIX}-pagination`,
    gridContainer: `${PREFIX}-gridContainer`,
    profilePostsTitle: `${PREFIX}-profilePostsTitle`,
    editButton: `${PREFIX}-editButton`,
};

const StyledGrow = styled(Grow)((
    {
        theme
    }
) => ({
    [`& .${classes.profileHead}`]: {
      borderRadius: 15,
      marginBottom: '12px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent:'space-between',
      padding: '16px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '12px',
        //marginTop: '-12px',
      },
    },

    [`& .${classes.editButton}`]: {
      borderRadius: 15,
      color: 'lightGrey', //#424242
      borderColor: 'white',
      marginLeft: '10px',
      minWidth:'45px',
      maxWidth:'45px',
      textTransform: 'none',
      fontSize: '16px',
      fontWeight: 'light',
      "&:hover": {
        boxShadow: "#000000",
        //background: "#F0EFEF"
      },
    },

    [`& .${classes.pagination}`]: {
      borderRadius: 15,
      marginTop: '24px',
      padding: '16px',
    },

    [`& .${classes.gridContainer}`]: {
      //[theme.breakpoints.down('xs', 'xl')]: {
        //flexDirection: 'column-reverse',
      //},
    },

    [`& .${classes.profilePostsTitle}`]: {
        padding: '8px 16px 16px 16px',
    },
  
}));

export default StyledGrow;