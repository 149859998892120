import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper, Container, Grid, Chip } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import BookmarksOutlinedIcon from '@mui/icons-material/BookmarksOutlined';
import TravelExploreOutlinedIcon from '@mui/icons-material/TravelExploreOutlined';
import StarOutlineOutlinedIcon from '@mui/icons-material/StarOutlineOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

//import { getTopContributors } from '../../actions/posts'

import twitterLogo from '../../images/twitter_logo.png';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledSideBar from './sideBarStyles';

const theme = createTheme();
const PREFIX = 'SideBar';
const classes = {
  appBar: `${PREFIX}-appBar`,
  categoryTitle: `${PREFIX}-categoryTitle`,
  categoryButton: `${PREFIX}-categoryButton`,
  profileLink: `${PREFIX}-profileLink`
};

const categoriesToShow = [
  {value: 'Self Improvement'},
  {value: 'Startup'},
  {value: 'Technology'},
  {value: 'Design'},
  {value: 'Philosophy'},
  {value: 'Society'},
];

const SideBar = ({  }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const userId = user?.result?._id;
  //const { contributors } = useSelector((state) => state.posts);
  //const topContributors = contributors?.topContributors

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const displayCategory = (category) => {
    history.push(`/stories/category?category=${category}`);
  };

  const goToHome = () => {
    history.push(`/stories`);
  }

  const goToSaved = () => {
    history.push(`/stories/saved`);
  }

  const goToEditFollowing = () => {
    history.push(`/profile/editfollowing`);
  }

  const goToProfile = () => {
    history.push(`/profile/${userId}`);
  }

  const goToExploreCircles = () => {
    history.push(`/stories/circles`);
  }

  const goToTopContributors = () => {
    history.push(`/stories/topcontributors`);
  }

  const goToTwitterProfile = () => {
    window.open('https://twitter.com/circletree_app', '_blank');
  };

  //useEffect(() => {
  //  dispatch(getTopContributors());
  //}, []);

    return <StyledSideBar position={isMobile ? "standard": "fixed"} padding='0px 0% 0px 0px'>
                <Paper className={classes.appBar} elevation={0} >

                  <Button onClick={goToHome}
                    sx={{ color: 'lightGrey', borderColor: 'black', textTransform:'none', fontSize: '16px', fontWeight: 'medium', borderRadius:'40px', paddingRight:'15px', paddingLeft:'15px', marginBottom:'10px' }} size="small"
                  >
                    <HomeOutlinedIcon/>
                    &nbsp;&nbsp;Home
                  </Button>

                  <Button onClick={goToSaved}
                    sx={{ color: 'lightGrey', borderColor: 'black', textTransform:'none', fontSize: '16px', fontWeight: 'medium', borderRadius:'40px', paddingRight:'15px', paddingLeft:'15px', marginBottom:'10px' }} size="small"
                  >
                    <BookmarksOutlinedIcon/>
                    &nbsp;&nbsp;Saved
                  </Button>

                  <Button onClick={goToEditFollowing}
                    sx={{ color: 'lightGrey', borderColor: 'black', textTransform:'none', fontSize: '16px', fontWeight: 'medium', borderRadius:'40px', paddingRight:'15px', paddingLeft:'15px', marginBottom:'10px' }} size="small"
                  >
                    <StarOutlineOutlinedIcon/>
                    &nbsp;&nbsp;Following
                  </Button>

                  <Button onClick={goToProfile}
                    sx={{ color: 'lightGrey', borderColor: 'black', textTransform:'none', fontSize: '16px', fontWeight: 'medium', borderRadius:'40px', paddingRight:'15px', paddingLeft:'15px', marginBottom:'10px' }} size="small"
                  >
                    <AccountCircleOutlinedIcon/>
                    &nbsp;&nbsp;Profile
                  </Button>

                  <Button onClick={goToExploreCircles}
                    sx={{ color: 'lightGrey', borderColor: 'black', textTransform:'none', fontSize: '16px', fontWeight: 'medium', borderRadius:'40px', paddingRight:'15px', paddingLeft:'15px', marginBottom:'10px' }} size="small"
                  >
                    <TravelExploreOutlinedIcon/>
                    &nbsp;&nbsp;Explore circles
                  </Button>

                  <Button onClick={goToTopContributors}
                    sx={{ color: 'lightGrey', borderColor: 'black', textTransform:'none', fontSize: '16px', fontWeight: 'medium', borderRadius:'40px', paddingRight:'15px', paddingLeft:'15px', marginBottom:'10px' }} size="small"
                  >
                    <LeaderboardOutlinedIcon/>
                    &nbsp;&nbsp;Top contributors
                  </Button>

                  <Button onClick={goToTwitterProfile}
                    sx={{ color: 'lightGrey', borderColor: 'black', textTransform:'none', fontSize: '16px', fontWeight: 'medium', borderRadius:'40px', paddingRight:'15px', paddingLeft:'17px', marginBottom:'10px' }} size="small"
                  >
                    <img src={twitterLogo} height="20px" alt="twitter" style={{ opacity: 0.85 }} />
                    &nbsp;&nbsp;Contact us
                  </Button>

                </Paper>
            </StyledSideBar>
};

export default SideBar;