import { styled } from '@mui/material/styles';
import { Pagination } from '@mui/material';

const PREFIX = 'Paginate';

const classes = {
    ul: `${PREFIX}-ul`,
    pageButton: `${PREFIX}-pageButton`
};

const StyledPagination = styled(Pagination)(() => ({
    [`& .${classes.ul}`]: {
        justifyContent: 'space-around',
        "& .MuiPaginationItem-root": {
            //color: "DarkGrey",
            //backgroundColor: "#ffffff",
            //borderColor: "transparent"
        },
        "& .MuiPaginationItem-page": {
            //color: "black",
            //backgroundColor: "#ffffff",
            //borderColor: "transparent"
        },
        "& .Mui-selected": {
            //color: "LightBlue",
            //backgroundColor: "transparent",
            //borderColor: "transparent"
        },
    },

    [`& .${classes.pageButton}`]: {
        textTransform: 'none',
        fontSize: '16px',
        "&:hover": {
          boxShadow: "#000000",
          background: "#000000"
        },
    }
}));

export default StyledPagination;