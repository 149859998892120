import React, { useState } from 'react';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const Filters = ({ updateMetricFilter, updateFormatFilter, metricFilter, formatFilter }) => {

  const handleMetricFilterChange = (event) => {
    updateMetricFilter(event.target.value);
  };

  const handleFormatFilterChange = (event) => {
    updateFormatFilter(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ margin: '0px 10px 20px 0px', borderRadius: '40px', maxHeight: '35px' }}>
        <Select value={metricFilter} onChange={handleMetricFilterChange} sx={{ borderRadius: '40px', maxHeight: '35px', '& .MuiOutlinedInput-notchedOutline': {borderColor: 'transparent'} }}>
          <MenuItem value="trending">Trending</MenuItem>
          <MenuItem value="mostLiked">Most liked</MenuItem>
          <MenuItem value="newest">Last uploaded</MenuItem>
        </Select>
      </FormControl>

      {/*<FormControl sx={{ margin: '0px 0px 20px 0px', borderRadius: '40px', maxHeight: '35px' }}>
        <Select value={formatFilter} onChange={handleFormatFilterChange} sx={{ borderRadius: '40px', maxHeight: '35px', '& .MuiOutlinedInput-notchedOutline': {borderColor: '#dddddd'}}}>
          <MenuItem value="All">Formats</MenuItem>
          <MenuItem value="Video">Video</MenuItem>
          <MenuItem value="Book">Book</MenuItem>
          <MenuItem value="Podcast">Podcast</MenuItem>
          <MenuItem value="Article">Article</MenuItem>
        </Select>
      </FormControl>*/}
    </div>
  );
};

export default Filters;
