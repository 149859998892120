import React from 'react';
import { Container, Grid } from '@mui/material';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import '@fontsource/inter/300.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import useMediaQuery from '@mui/material/useMediaQuery';

const theme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {main: '#90caf9'},
    background: {
        default: "#FEFEFc", // #FEFEF1
        paper: "#FEFEFc",
      },
      typography: {
        //fontFamily: 'poppins, sans-serif',
        fontSize: 14,
      },
    }
});

import Navbar from './components/Navbar/Navbar';
import SideBar from './components/SideBar/SideBar';
import AudioPlayer from './components/AudioPlayer/AudioPlayer';
import Home from './components/Home/Home';
import Auth from './components/Auth/Auth';
import UserProfile from './components/UserProfile/UserProfile';
import UserList from './components/UserList/UserList';
import CreatePostPage from './components/createPostPage';
import EditPostPage from './components/EditPostPage';
import PostDetails from './components/PostDetails/PostDetails';
import EditProfile from './components/EditProfile/EditProfile';
import EditFollowing from './components/EditFollowing/EditFollowing';
import ExplorePage from './components/ExplorePage/ExplorePage';
import ContributorsRanking from './components/ContributorsRanking/ContributorsRanking';

const App = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });
    
    return(
        <BrowserRouter>
        <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Grid container justifyContent="center" alignItems="center" >
            <Grid item xs={11.25} sm={11.25} md={6} xl={4}>
                <Navbar/>
                <Switch>
                    <Route path="/" exact component={() => <Redirect to="/stories" />} />
                    <Route path="/stories" exact component={Home} />
                    <Route path="/stories/search" exact component={Home} />
                    <Route path="/stories/category" exact component={Home} />
                    <Route path="/stories/saved" component={UserList} />
                    <Route path="/stories/circles" component={ExplorePage} />
                    <Route path="/stories/topcontributors" component={ContributorsRanking} />
                    <Route path="/stories/:id" component={PostDetails} />
                    <Route path="/profile/editprofile" component={EditProfile} />
                    <Route path="/profile/editfollowing" component={EditFollowing} />
                    <Route path="/profile/:id" component={UserProfile} />
                    <Route path="/create" component={CreatePostPage} />
                    <Route path="/edit" component={EditPostPage} />
                    <Route path="/auth" exact component={() => (!user ? <Auth /> : <Redirect to="/stories" />)} />
                </Switch>
                {/*<AudioPlayer/>*/}
            </Grid>
        </Grid>
        </ThemeProvider>
        </BrowserRouter>
    )
};
// BrowserRouter: router implementation for HTML5 browser (use different url for different...)
// Switch: either show the home either show the auth
// first check if the user is logged in before showing Auth page

export default App;