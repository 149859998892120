import { FETCH_PROFILE, FETCH_EDIT_PROFILE, START_LOADING, END_LOADING } from '../constants/actionTypes';

export default (state = { isLoading: true, profile: null, posts: [] }, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true }
        case END_LOADING:
            return { ...state, isLoading: false }
        case FETCH_PROFILE:
            return { ...state, profile: action.payload.profile, posts: action.payload.posts };
        case FETCH_EDIT_PROFILE:
            const localStorageProfileData = JSON.parse(localStorage.getItem('profile'));
            localStorageProfileData.result = { ...action.payload }
            localStorage.setItem('profile', JSON.stringify({ result: localStorageProfileData.result, token: localStorageProfileData.token }));
            return { ...state, profile: action.payload };
        default:
            return state;
    }
}