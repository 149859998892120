import { AUTH } from '../constants/actionTypes';
import * as api from '../api';

// function that returns an action
// action creators are asynchronous (we have to use redux tank) -> function that returns an async function with a dispatch

export const signin = (formData, history) => async (dispatch) => {
    try {
        //log in the user
        const { data } = await api.signIn(formData);

        dispatch({ type: AUTH, data });

        history.goBack(); // go to home page after log in
    } catch (error) {
        console.log(error);
    }
};

export const signup = (formData, history) => async (dispatch) => {
    try {
        //sign up the user..
        const { data } = await api.signUp(formData);

        dispatch({ type: AUTH, data });

        history.push('/profile/editfollowing'); // go to following page after sign up
    } catch (error) {
        console.log(error);
    }
};

export const signinGoogleUser = (result, history) => async (dispatch) => {
    try {
        //log in the user
        const { data } = await api.signinGoogleUser(result);

        dispatch({ type: AUTH, data });

        if (data?.result?.followedCircles.length === 0 && data?.result?.followedTags.length === 0) {
            history.push('/profile/editfollowing'); // if no existing following, go to following page after log in
        } else {
            history.goBack(); // if existing following, go to home page after log in
        }
    } catch (error) {
        console.log(error);
    }
};