import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Box, Typography, Button } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider, createTheme } from '@mui/material/styles';
const theme = createTheme();

import bgImage from '../../images/landing_image3.png';

const useStyles = makeStyles((theme) => ({
  topFrame: {
    position: 'relative',
    overflow: 'hidden',
  },
  backgroundImg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.07,
    zIndex: -1,
  },
  content: {
    display: 'flex',
    //justifyContent: 'space-between',
    flexDirection: 'column',
    //justifyContent: 'center',
    //alignItems: 'center',
    padding: '20px', //theme.spacing(3),
    margin: '20px 0px 20px 0px',
    zIndex: 1,
  },
  leftSection: {
    flex: 1,
  },
  rightSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '14px 0px 0px',
  },
  title: {
    marginTop:'5px',
    textAlign: 'center',
    lineHeight: '1.35',
  },
  description: {
    textAlign: 'center',
    color: 'lightGrey',
  },
  noteLogin: {
    fontSize: 14,
    marginLeft:'8px',
    textAlign: 'center',
    color: '#8c8c8c',
  },
  loginButton: {
    borderRadius: 40,
    textTransform: 'none',
    fontSize: '16px',
    "&:hover": {
      boxShadow: "#000000",
      //background: "#39796d"
    },
  },
}));

const LandingIntro = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  return (
    <Box className={classes.topFrame}>
      {/*<img
        className={classes.backgroundImg}
        src={bgImage}
        alt="Background"
      />*/}
      <Box className={classes.content}>
        {/*<Box className={classes.leftSection}>*/}
          <Typography variant="h6" sx={{ fontWeight: 'bold' }} className={classes.title}>
            Relax and learn new things
          </Typography>
          {/*<Typography variant="h6" sx={{ fontWeight: 'bold' }} className={classes.title}>
            in the format humans love the most: stories.
          </Typography>*/}
          
          {/*<Typography variant="body1" className={classes.description} margin={isMobile ? '10px 0px 5px 0px' : '5px 0px 5px 0px'}>
          Our ambition is to become the social platform of reference for sharing and accessing the best knowledge.
          </Typography>*/}
          
        {/*</Box>*/}
        <Box className={classes.rightSection}>
          <Button variant="contained" size="small" className={classes.loginButton}
          component={Link} to="/auth" disableElevation
          sx={{ color: 'white', borderColor: 'black'}}
          >
            Sign Up
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingIntro;