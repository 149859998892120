import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const PREFIX = 'Home';

const classes = {
    appBarSearch: `${PREFIX}-appBarSearch`,
    pagination: `${PREFIX}-pagination`,
    gridContainer: `${PREFIX}-gridContainer`,
    inputField: `${PREFIX}-inputField`,
    searchButton: `${PREFIX}-searchButton`,
    categoryTitle: `${PREFIX}-categoryTitle`,
    allPostsTitle: `${PREFIX}-allPostsTitle`,
    categoryDescription: `${PREFIX}-categoryDescription`
};

const StyledGrow = styled(Grid)((
    {
        theme
    }
) => ({
    [`& .${classes.appBarSearch}`]: {
      borderRadius: 15,
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '16px',
    },

    [`& .${classes.pagination}`]: {
      borderRadius: 15,
      marginTop: '24px',
      //padding: '4px 30% 4px 30%',
      backgroundColor: 'transparent'
    },

    [`& .${classes.gridContainer}`]: {
      //[theme.breakpoints.down('xs', 'xl')]: {
        //flexDirection: 'column-reverse',
      //},
    },
    
    [`& .${classes.inputField}`]: {
        textTransform: 'none',
        fontSize: '16px',
        '& label.Mui-focused': {
            color: 'grey',
        },
        '& .MuiInputBase-root': {
          backgroundColor: '#ffffff',
          borderRadius: '40px',
          paddingLeft:'12px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#C3C2C2',
              borderRadius: '40px'
            },
        },
    },

    [`& .${classes.searchButton}`]: {
        borderRadius: 40,
        textTransform: 'none',
        fontSize: '16px',
        "&:hover": {
            boxShadow: "#000000",
            background: "#000000"
          },
      },

      [`& .${classes.categoryTitle}`]: {
        padding: '0px 16px 4px 13px',
          },

      [`& .${classes.categoryDescription}`]: {
        padding: '0px 16px 16px 13px',
          },

      [`& .${classes.allPostsTitle}`]: {
        padding: '0px 16px 5px 13px',
          },
  
}));

export default StyledGrow;