import { styled } from '@mui/material/styles';
import { Toolbar } from '@mui/material';

const PREFIX = 'SearchBar';

const classes = {
    appBarSearch: `${PREFIX}-appBarSearch`,
    inputField: `${PREFIX}-inputField`,
    searchButton: `${PREFIX}-searchButton`,
};

const StyledToolbar = styled(Toolbar)((
    {
        theme
    }
) => ({

    [`& .${classes.appBarSearch}`]: {
        minWidth: '500px',
        [theme.breakpoints.down('md')]: {
            minWidth: '0px',
          },
      },
    
    [`& .${classes.inputField}`]: {
        textTransform: 'none',
        fontSize: '16px',
        '& label.Mui-focused': {
            color: 'grey',
        },
        '& .MuiInputBase-root': {
          backgroundColor: 'rgba(35, 35, 35, 0.05)',
          borderRadius: '40px',
          paddingLeft:'12px'
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#dddddd',
              borderRadius: '40px'
            },
            '&.Mui-focused fieldset': { // - Set the Input border when parent is focused 
                borderColor: '#90caf9',
            },
        },
        '&:hover fieldset': {
          borderColor: '#bbbbbb',
        },
    },

    [`& .${classes.searchButton}`]: {
        borderRadius: 15,
        color: 'darkGrey',
        //backgroundColor: 'white',
        //borderColor: 'white',
        marginRight: '-15px',
        textTransform: 'none',
        fontSize: '16px',
        "&:hover": {
          //boxShadow: "#000000",
          //background: "#F0EFEF"
        },
        [theme.breakpoints.down('md')]: {
          minWidth:'45px',
          maxWidth:'45px',
          minHeight:'45px',
          maxHeight:'45px',
        },
      },
  
}));

export default StyledToolbar;