import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const PREFIX = 'Form';

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    form: `${PREFIX}-form`,
    inputField: `${PREFIX}-inputField`,
    fileInput: `${PREFIX}-fileInput`,
    buttonSubmit: `${PREFIX}-buttonSubmit`
};

const StyledPaper = styled(Paper)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
      '& .MuiTextField-root': {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
      },
    },

    [`&.${classes.paper}`]: {
      //backgroundColor: '#141414',
      padding: '0px 20px 0px 20px',
      borderRadius: 15,
      [theme.breakpoints.down('md')]: {
        padding: 0,
      },
    },

    [`& .${classes.form}`]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },

    [`& .${classes.inputField}`]: {
        textTransform: 'none',
        fontSize: '16px',
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderRadius: '10px'
            },
        },
    },

    [`& .${classes.fileInput}`]: {
      width: '100%',
      margin: '16px 0px 0px',
    },

    [`& .${classes.buttonSubmit}`]: {
      margin: '24px 0px 20px',
      borderRadius: 40,
      textTransform: 'none',
      fontSize: '16px',
      "&:hover": {
        boxShadow: "#000000",
        //background: "#39796d"
      },
    }
}));

export default StyledPaper;