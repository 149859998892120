export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const COMMENT = 'COMMENT';
export const FETCH_POST = 'FETCH_POST';
export const FETCH_ALL = 'FETCH_ALL';
export const FETCH_BY_SEARCH = 'FETCH_BY_SEARCH';
export const FETCH_BY_CATEGORY = 'FETCH_BY_CATEGORY';
export const FETCH_BY_USER_LIST = 'FETCH_BY_USER_LIST';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_EDIT_PROFILE = 'FETCH_EDIT_PROFILE';
export const FETCH_TOP_CONTRIBUTORS = 'FETCH_TOP_CONTRIBUTORS';

export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';

export const AUTH = 'AUTH';
export const LOGOUT = 'LOGOUT';

// to be able to detect errors with actions that are at the beginning strings (if we misspell the variables)