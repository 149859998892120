import React, { useState, useEffect, useRef } from 'react';
import { TextField, Button, Typography, Paper, Tabs, Tab, TabPanel, Box, Grid, Container, CircularProgress, Chip, InputAdornment, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import AudioFileOutlinedIcon from '@mui/icons-material/AudioFileOutlined';
import CloseIcon from '@mui/icons-material/Close';
import FileBase from 'react-file-base64';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from "prop-types";

import cheerio from 'cheerio';
import axios from 'axios';

import { createPost, createAISummary } from '../../actions/posts';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledPaper from './formStyles';

const theme = createTheme();
const PREFIX = 'Form';
const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    form: `${PREFIX}-form`,
    inputField: `${PREFIX}-inputField`,
    fileInput: `${PREFIX}-fileInput`,
    buttonSubmit: `${PREFIX}-buttonSubmit`
};

const categories = [
    {value: 'Non-Fiction'},
    {value: 'Fiction'},
    {value: 'Personal'},
    {value: 'Fun'},
  ];

const formats = [
    {value: 'Video'},
    {value: 'Book'},
    {value: 'Podcast'},
    {value: 'Article'},
];


const Form = ({ currentId, setCurrentId }) => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const [postData, setPostData] = useState({
        type: 'post', title: '', message: '', selectedFile: '', tags: '',
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedAudio, setSelectedAudio] = useState(null);
    const note = "We will improve the classification system over time."
    const note2 = `For now, please select a category and enter some tags to clarify the topic of your story. This helps readers find your content.`
    const note3 = "The title is limited to 120 characters and the content to 10,000 characters."
    const [noteExpanded, setNoteExpanded] = useState(false);
    const handleNoteExpanded = () => {
        setNoteExpanded(!noteExpanded);
    };
    // update post -> change the form
    const post = useSelector((state) => currentId ? state.posts.posts.find((p) => p._id === currentId) : null);
    const [loadingUpload, setLoadingUpload] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const userFollowing = {followedCircles: user?.result?.followedCircles, followedTags: user?.result?.followedTags}

    // for image
    const handleImageUpload = (event) => {
        const selectedImage = event.target.files[0];
        setSelectedImage(selectedImage);
        setPostData({ ...postData, selectedFile: selectedImage})
      };

    //for tag system
    const [tags, setTags] = useState([]);
    const [tagInput, setTagInput] = useState('');
    const handleTagInputChange = (event) => {
        setTagInput(event.target.value);
    };
    const handleTagInputKeyDown = (event) => {
        if (event.key === 'Enter') {
        event.preventDefault();
        addTag();
        }
    };
    const addTag = () => {
        if (tagInput.trim() !== '') {
        setTags([...tags, tagInput.trim()]);
        setTagInput('');
        }
    };
    const removeTag = (index) => {
        const updatedTags = [...tags];
        updatedTags.splice(index, 1);
        setTags(updatedTags);
    };
    useEffect(() => {
        setPostData({ ...postData, tags: tags });
      }, [tags]);

    //function to update post
    useEffect(() => {
        if(post) setPostData(post);
    }, [post]);

    const clear = () => {
        setCurrentId(null);
        setPostData({ type: 'post', title: '', message: '', selectedFile: '', tags: '' });
    };

    const handleDeleteImage = () => {
        URL.revokeObjectURL(selectedImage); // Clean up the object URL
        setSelectedImage(null);
      };

    const handleSubmitPost = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('type', postData.type);
        formData.append('name', user?.result?.name);
        formData.append('title', postData.title);
        formData.append('message', postData.message);
        formData.append('selectedFile', postData.selectedFile);
        formData.append('tags', postData.tags);

        if (currentId === null) {
            setLoadingUpload(true);
            
                    dispatch(
                    createPost(formData, history)
                      //createPost({
                      //  ...postData,
                      //  name: user?.result?.name,
                      //  title: postData.title,
                      //  format: "Post",
                      //  message: postData.message,
                      //  duration: postData.duration,
                      //  selectedFile: postData.selectedFile,
                      //}, history)
                    );
                    clear();
                  }
        };

    const [disable2, setDisable2] = useState(true); // disable submit button if no input
    useEffect(() => {
        (postData.title && postData.message) ? setDisable2(false) : setDisable2(true);
    }, [postData.title, postData.category, postData.message]);

    const fileInputRef = useRef(null);

    const handleClick = () => {
        fileInputRef.current.click();
    };

    if(!user?.result?.name) {
        return (
            <StyledPaper className={classes.paper} elevation={0}>
                <Typography variant="h6" align="center">
                    Log in to upload your own stories
                </Typography>
            </StyledPaper>
        );
    };
    
    return (
        <Grid >
        <Container component="main" maxWidth="sm" >
        <StyledPaper className={classes.paper} elevation={0}>

                <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`}
                    onSubmit={handleSubmitPost}>
                        <div className={`${classes.root} ${classes.form}`}>                        
                        <Typography component="h1" variant="h6" marginTop="25px" marginBottom="10px" fontWeight='bold'>Write a post</Typography>

                        {/*<TextField className={classes.inputField} variant="outlined" select name="category" fullWidth required
                            value={postData.category}
                            label={"Category"}
                            onChange={(e) => setPostData({ ...postData, category: e.target.value })} >
                            {categories.map((category) => category.value).map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </TextField>*/}

                        <TextField className={classes.inputField} variant="outlined" name="title" fullWidth required
                            value={postData.title}
                            label={"Title"}
                            multiline
                            inputProps={{maxLength: 120}}
                            onChange={(e) => setPostData({ ...postData, title: e.target.value })} >
                        </TextField>
                        <Grid container alignItems="center" justifyContent="flex-end">
                        <Typography color="textSecondary" sx={{ fontSize: '10px' }} marginTop="-12px" marginBottom="-8px">{postData.title.length} / 120</Typography>
                        </Grid>
                        <TextField className={classes.inputField} variant="outlined" name="content" fullWidth required
                            value={postData.message}
                            label={"Content"}
                            multiline
                            minRows={3}
                            inputProps={{maxLength: 10000}}
                            onChange={(e) => setPostData({ ...postData, message: e.target.value })} >
                        </TextField>
                        <Grid container alignItems="center" justifyContent="flex-end">
                        <Typography color="textSecondary" sx={{ fontSize: '10px' }} marginTop="-12px" marginBottom="-8px">{postData.message.length} / 10 000</Typography>
                        </Grid>

                        <Grid container alignItems="center" justifyContent="flex-start" sx={{ margin: '8px 0px 8px 0px' }}>
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '12px' }}>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<ImageOutlinedIcon />}
                                onClick={handleClick}
                                sx={{ textTransform: 'none', color: '#707070', borderColor: '#bbbbbb', borderRadius: '12px', fontSize: '16px', "&:hover": {borderColor: '#707070'}}}
                                size="medium"
                                disableElevation
                            >
                                {selectedImage ? 'Change image' : 'Add image'}
                            </Button>
                            {selectedImage && (
                            <IconButton
                                onClick={handleDeleteImage}
                                color="#707070"
                                sx={{ marginLeft: 1 }} // Add some spacing between buttons
                            >
                                <CloseIcon />
                            </IconButton>
                            )}
                        </div>
                        </Grid>

                        <div>
                        {selectedImage && (
                            <img 
                            src={URL.createObjectURL(selectedImage)} 
                            alt="Uploaded"
                            style={{ width: '100%', height: 'auto', margin: '5px 0px 2px 0px', borderRadius: 8 }} 
                            />
                        )}
                        </div>

                        {/*<Grid container alignItems="center" justifyContent="flex-start" sx={{ margin: '8px 0px 8px 0px' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="file"
                                ref={fileInputRef}
                                onChange={handleImageUpload}
                                style={{ display: 'none' }}
                            />
                            <Button
                                variant="outlined"
                                color="primary"
                                startIcon={<AudioFileOutlinedIcon />}
                                onClick={handleClick}
                                sx={{ textTransform: 'none', color: '#707070', borderColor: '#bbbbbb', borderRadius: '12px', fontSize: '16px', "&:hover": {borderColor: '#707070'}}}
                                size="medium"
                                disableElevation
                            >
                                {selectedAudio ? 'Change audio' : 'Add audio'}
                            </Button>
                            {selectedAudio && (
                            <IconButton
                                onClick={handleDeleteImage}
                                color="#707070"
                                sx={{ marginLeft: 1 }} // Add some spacing between buttons
                            >
                                <CloseIcon />
                            </IconButton>
                            )}
                        </div>
                        </Grid>*/}

                        <TextField className={classes.inputField}
                            label="Tags"
                            helperText={`Click "Enter" to add`}
                            value={tagInput}
                            onChange={handleTagInputChange}
                            onKeyDown={handleTagInputKeyDown}
                            fullWidth
                            variant="outlined"
                            InputProps={{
                                startAdornment: tags.length > 0 && (
                                <InputAdornment position="start">
                                    {tags.map((tag, index) => (
                                        <Chip
                                            key={index}
                                            label={tag}
                                            onDelete={() => removeTag(index)}
                                            //variant="outlined"
                                            sx={{margin:'0px 0px 2px 5px', height: '28px'}}
                                        />
                                    ))}
                                </InputAdornment>
                                ),
                            }}
                        />
                        </div>
                        
                        {(loadingUpload && (<CircularProgress margin="20px 0px 20px 0px" />))}
                        {(!loadingUpload && (
                        <Button
                            className={classes.buttonSubmit}
                            sx={{ color: 'white', borderColor: 'black'}}
                            variant="contained"
                            size="medium"
                            type="submit"
                            disableElevation
                            disabled={disable2}>
                                Post
                        </Button>
                        ))}
                    </form>

            <Grid marginTop='15px' marginBottom='20px'>
            <Accordion expanded={noteExpanded} onChange={handleNoteExpanded} elevation={0}>
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{  }}/>} aria-controls="panel-content" sx={{  }}>
                    <Typography sx={{ textDecoration: 'underline' }}>Guidelines</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{  }}>
                    <Typography marginTop='-10px'> {note} </Typography>
                    <Typography marginTop='10px'> {note2} </Typography>
                    <Typography marginTop='10px'> {note3} </Typography>
                </AccordionDetails>
            </Accordion>
            </Grid>

        </StyledPaper>
        </Container>
        </Grid>
    );
}
// e.preventDefault(); to not get the refresh in the browser at this moment
// if current id not nul, don't dispatch a create post -> update it
//Paper: white background
// ...postData to not override everything in onChange -> spread the postData, then creator

export default Form;