import { styled } from '@mui/material/styles';

const PREFIX = 'CommentSection';

const classes = {
  submitCommentButton: `${PREFIX}-submitCommentButton`,  
  commentField: `${PREFIX}-commentField`,
  commentsOuterContainer: `${PREFIX}-commentsOuterContainer`,
  commentsInnerContainer: `${PREFIX}-commentsInnerContainer`
};

const Root = styled('strong')((
    {
        theme
    }
) => ({
  [`& .${classes.submitCommentButton}`]: {
    borderRadius: 40,
    //margin: theme.spacing(3, 0, 2),
    textTransform: 'none',
    fontSize: '16px',
    "&:hover": {
      //boxShadow: "#000000",
      //background: "#e9e9e9"
    },
  },

  [`& .${classes.commentField}`]: {
    textTransform: 'none',
    fontSize: '16px',
    margin:'-15px 0px 0px 0px',
    '& label.Mui-focused': {
        color: 'grey',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
          //borderColor: 'grey',
          borderRadius: '12px'
        },
    },
},
  
  [`& .${classes.commentsOuterContainer}`]: {
      //display: 'flex',
      justifyContent: 'space-between',
      marginTop:'20px',
      marginBottom:'20px',
    },

    [`& .${classes.commentsInnerContainer}`]: {
      //height: '200px',
      //overflowY: 'auto', marginRight: '30px',
    }
}));

export default Root;