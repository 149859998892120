import { styled } from '@mui/material/styles';
import { Card } from '@mui/material';

const PREFIX = 'Comment';

const classes = {
    border: `${PREFIX}-border`,
    fullHeightCard: `${PREFIX}-fullHeightCard`,
    card: `${PREFIX}-card`,
    media: `${PREFIX}-media`,
    overlay: `${PREFIX}-overlay`,
    overlay2: `${PREFIX}-overlay2`,
    grid: `${PREFIX}-grid`,
    title: `${PREFIX}-title`,
    details: `${PREFIX}-details`,
    content: `${PREFIX}-content`,
    moreOptions: `${PREFIX}-moreOptions`,
    profileLink: `${PREFIX}-profileLink`,
    body: `${PREFIX}-body`,
    cardActions: `${PREFIX}-cardActions`,
    cardAction: `${PREFIX}-cardAction`,
    cardButton: `${PREFIX}-cardButton`
};

const StyledCard = styled(Card)({

  [`& .${classes.border}`]: {
    border: 'solid',
  },
  [`& .${classes.fullHeightCard}`]: {
    height: '100%',
  },
  [`&.${classes.card}`]: {
    //backgroundColor: '#000000',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '15px',
    height: '100%',
    position: 'relative',
  },
  [`& .${classes.media}`]: {
    height: 108,
    width: 180,
    borderRadius: 15,
    padding: '0px 0px 0px 0px',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    backgroundBlendMode: 'darken',
  },
  [`& .${classes.overlay}`]: {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'black',
  },
  [`& .${classes.overlay2}`]: {
    position: 'absolute',
    top: '40px',
    right: '20px',
    color: 'black',
  },
  [`& .${classes.grid}`]: {
    display: 'flex',
  },
  [`& .${classes.title}`]: {
    fontSize: '18px',
    padding: '0px 8px 0px 8px',
  },
  [`& .${classes.details}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    //margin: '20px',
    padding: '5px 5px 5px 5px',
  },
  [`& .${classes.content}`]: {
    display: 'flex',
    //justifyContent: 'space-between',
    //margin: '20px',
    padding: '5px 5px 5px 5px',
  },
  [`& .${classes.moreOptions}`]: {
    borderRadius: 15,
    display: 'flex',
    justifyContent: 'center',
    "&:hover": {
      boxShadow: "#000000",
      //background: "#F0EFEF"
    },
    minWidth:'35px',
    maxWidth:'35px',
  },

  [`& .${classes.profileLink}`]: {
    borderRadius: 15,
    padding: '5px 10px 5px 10px',
    background:'rgba(35, 35, 35, 0.05)', // 292929
    "&:hover": {
      //boxShadow: "#000000",
      background: "rgba(35, 35, 35, 0.1)" // 333333
    },
  },

  [`& .${classes.body}`]: {
    //display: 'flex',
    //justifyContent: 'space-between',
    padding: '5px 15px 5px 15px',
  },
  [`& .${classes.cardActions}`]: {
    padding: '5px 5px 5px 5px',
    display: 'flex',
    //justifyContent: 'space-between',
  },
  [`& .${classes.cardAction}`]: {
    display: 'block',
    textAlign: 'initial',
  },
  [`& .${classes.cardButton}`]: {
    borderRadius: 15,
    textTransform: 'none',
    fontSize: '15px',
    color:'#5BA8BA',
    "&:hover": {
      boxShadow: "#000000",
      //background: "#F0EFEF"
    },
  },
});

export default StyledCard;