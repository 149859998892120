import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const PREFIX = 'ExplorePage';

const classes = {
  appBar: `${PREFIX}-appBar`,
  categoryTitle: `${PREFIX}-categoryTitle`,
  categoryButton: `${PREFIX}-categoryButton`
};

const StyledSideBar = styled(Grid)((
  {
    theme
  }
  ) => ({
  [`& .${classes.appBar}`]: {
    //backgroundColor:'#151515', //fafafa
    borderRadius: 15,
    //justifyContent: 'space-between',
    //justifyContent: "flex-start",
    //padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.categoryTitle}`]: {
    display:"flex",
    //fontSize: '18px',
    padding: '8px 0px 0px 7px',
    [theme.breakpoints.down('md')]: {
      padding: '8px 16px 0px 16px',
    },
  },

  [`& .${classes.categoryButton}`]: {
    color: '#7bacd7', //#94e0e0
    borderRadius: 15,
    marginBottom:'5px',
    textTransform: 'none',
    fontSize: '16px',
    "&:hover": {
      //boxShadow: "#000000",
      //background: "#F0EFEF"
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: "flex-start",
    },
  },

}));

export default StyledSideBar;