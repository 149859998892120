import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper, Tabs, Tab, Box, Grid, Container, CircularProgress, Chip, InputAdornmentt, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuItem from '@mui/material/MenuItem';
import FileBase from 'react-file-base64';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PropTypes from "prop-types";

import { editProfile } from '../../actions/profile';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledPaper from './editFollowingStyles';

const theme = createTheme();
const PREFIX = 'EditFollowing';
const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    form: `${PREFIX}-form`,
    inputField: `${PREFIX}-inputField`,
    fileInput: `${PREFIX}-fileInput`,
    buttonSubmit: `${PREFIX}-buttonSubmit`,
    buttonCancel: `${PREFIX}-buttonCancel`,
    circleChip: `${PREFIX}-circleChip`,
};

const categories = [
    {value: 'Technology'},
    {value: 'Startup'},
    {value: 'Psychology'},
    {value: 'Self Improvement'},
    {value: 'Design'},
    {value: 'Leadership'},
    {value: 'Society'},
    {value: 'Philosophy'},
    {value: 'Environment'},
    {value: 'Marketing'},
    {value: 'Business'},
    {value: 'Finance'},
    {value: 'Art'},
    {value: 'History'},
    {value: 'Geography'},
    {value: 'Economics'},
    {value: 'Sociology'},
    {value: 'AI'},
    {value: 'Programming'},
    {value: 'Engineering'},
    {value: 'Mathematics'},
    {value: 'Biology'},
    {value: 'Physics'},
    {value: 'Literature'},
    {value: 'Music'},
    {value: 'Cinema'},
    {value: 'Health'},
    {value: 'Photography'}
    ];


const EditProfile = ({  }) => {
    // update post -> change the form
    const [loadingUpload, setLoadingUpload] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('profile'));
    const [noteExpanded, setNoteExpanded] = useState(false);
    const handleNoteExpanded = () => {
        setNoteExpanded(!noteExpanded);
    };
    const note = `Follow the circles and tags you want to see in your feed.`
    const note2 = "We will improve the classification system and the circles will grow over time."
    const note3 = `For now, you can follow the circles (e.g. "Technology") and enter the tags (e.g. "artificial intelligence" and "data science") you want to see appear in your feed.`
    const note4 = `You can always edit them by clicking on "Following" in the menu.`
    const [profileData, setProfileData] = useState({
        followedCircles: user?.result?.followedCircles, followedTags: user?.result?.followedTags,
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingUpload(true);
        dispatch(editProfile( user?.result?._id, { ...profileData}));
        setTimeout(() => {
            history.push(`/`);
        }, 2000);
      };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      };

    const handleCancel = () => {
        history.goBack();
      };

      const [disable, setDisable] = useState(true); // disable submit button if no input
      
      useEffect(() => {
        (profileData.followedCircles) ? setDisable(false) : setDisable(true);
      }, [profileData.followedCircles]);
        // remove later? not mandatory to follow someting?

    // Circles
    const handleCategoryToggle = (category) => {
        if (profileData.followedCircles.includes(category)) {
          setProfileData((prevProfileData) => ({
            ...prevProfileData,
            followedCircles: prevProfileData.followedCircles.filter(
              (followedCategory) => followedCategory !== category
            ),
          }));
        } else {
          setProfileData((prevProfileData) => ({
            ...prevProfileData,
            followedCircles: [...prevProfileData.followedCircles, category],
          }));
        }
      };
    

    // Tags
    const [tags, setTags] = useState(profileData.followedTags);
    const [tagInput, setTagInput] = useState('');
    const handleTagInputChange = (event) => {
        setTagInput(event.target.value);
    };
    const handleTagInputKeyDown = (event) => {
        if (event.key === 'Enter') {
        event.preventDefault();
        addTag();
        }
    };
    const addTag = () => {
        if (tagInput.trim() !== '') {
        setTags([...tags, tagInput.trim()]);
        setTagInput('');
        }
    };
    const addTagFromCategoryChips = (tag) => {
        setTags([...tags, tag.trim()]);
    };
    const removeTag = (index) => {
        const updatedTags = [...tags];
        updatedTags.splice(index, 1);
        setTags(updatedTags);
    };
    useEffect(() => {
        setProfileData({ ...profileData, followedTags: tags });
      }, [tags]);

      if(!user?.result?.name) {
          return (
              <StyledPaper className={classes.paper} elevation={0}>
                  <Typography variant="h6" align="center">
                      Log in for a personalized experience.
                  </Typography>
              </StyledPaper>
          );
      };

    
    return (
        <Grid >
        <Container component="main" maxWidth="sm" >
        <StyledPaper className={classes.paper} elevation={0}>

            <form autoComplete="off" noValidate className={`${classes.root} ${classes.form}`} onSubmit={handleSubmit} onKeyDown={handleKeyDown}>
                <div className={`${classes.root} ${classes.form}`}>

                <Grid display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                    <Typography component="h1" variant="h6" marginTop="10px" marginBottom="20px" fontWeight='bold' textAlign='center'>Followings</Typography>
                    {/*<Typography color="lightGrey">{note}</Typography>*/}
                </Grid>

                {/*<div>
                    <Typography variant="subtitle1" marginTop="10px" marginBottom="8px" fontWeight='bold'>Circles</Typography>
                    {categories.map((category) => (
                        <Chip
                        className={classes.circleChip}
                        key={category.value}
                        label={category.value}
                        clickable
                        //color={profileData.followedCircles.includes(category.value) ? 'primary' : 'default'}
                        onClick={() => handleCategoryToggle(category.value)}
                        style={{ marginRight: '0.5rem', marginBottom: '0.5rem', fontSize: '14px' }}
                        sx={profileData.followedCircles.includes(category.value) ? { color:'#000000', backgroundColor: '#90caf9' } : { backgroundColor: '#181818' }}
                        />
                    ))}
                </div>*/}

                <div>
                    {tags.map((tag, index) => (
                    <Chip
                        key={index}
                        label={tag}
                        onDelete={() => removeTag(index)}
                        //variant="outlined"
                        style={{ marginRight: '0.5rem', marginBottom: '0.5rem', fontSize: '14px' }}
                        color='primary'
                    />
                    ))}

                    <Typography variant="subtitle1" marginTop='20px' marginBottom='8px' fontWeight='bold'>Select topics</Typography>
                    
                    {categories.filter(category => !tags.includes(category.value)).map((category) => (
                        <Chip
                        className={classes.circleChip}
                        key={category.value}
                        label={category.value}
                        clickable
                        //color={profileData.followedCircles.includes(category.value) ? 'primary' : 'default'}
                        onClick={() => addTagFromCategoryChips(category.value)}
                        style={{ marginRight: '0.5rem', marginBottom: '0.5rem', fontSize: '14px' }}
                        sx={tags.includes(category.value) ? { color: 'Grey', backgroundColor: '#eeeeee' } : {  }}
                        />
                    ))}

                    <Typography variant="subtitle1" marginTop='20px' marginBottom='8px' fontWeight='bold'>Enter your own</Typography>
                    
                    <TextField
                        label={tagInput ? "" : "Add topic/keyword"}
                        value={tagInput}
                        onChange={handleTagInputChange}
                        onKeyDown={handleTagInputKeyDown}
                        fullWidth
                        variant="outlined"
                        helperText={`Click "Enter" to add`}
                        sx={{ margin: '0px 0px 0px 0px', '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderRadius: '10px'
                            },
                        }, }}
                    />
                </div>
                
                </div>
                
                <Grid display='flex' justifyContent='center' alignItems='center'>
                    {(loadingUpload && (<CircularProgress />))}
                    {(!loadingUpload && (
                    <Button
                        className={classes.buttonCancel} onClick={handleCancel}
                        sx={{ color: 'black', backgroundColor: 'transparent', borderColor: 'black'}}
                        variant="contained" size="small" disableElevation>
                            Cancel
                    </Button>
                    ))}
                    {(!loadingUpload && (
                    <Button
                        className={classes.buttonSubmit}
                        sx={{ color: 'white', borderColor: 'black'}}
                        variant="contained" size="small" disableElevation type="submit" disabled={disable}>
                            Save
                    </Button>
                    ))}
                </Grid>
            </form>

            {/*<Grid marginTop='10px' marginBottom='20px'>
            <Accordion expanded={noteExpanded} onChange={handleNoteExpanded} >
                <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'lightGrey' }}/>} aria-controls="panel-content"  sx={{ backgroundColor: 'transparent' }}>
                    <Typography variant="body1" color="#adadad" sx={{ textDecoration: 'underline' }}>Guidelines</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ backgroundColor: 'transparent' }}>
                    <Typography color="lightGrey" marginTop='-10px'> {note2} </Typography>
                    <Typography variant="body1" color="lightGrey" marginTop='10px'> {note3} </Typography>
                    <Typography variant="body1" color="lightGrey" marginTop='10px'> {note4} </Typography>
                </AccordionDetails>
            </Accordion>
            </Grid>*/}

        </StyledPaper>
        </Container>
        </Grid>
    );
}
// e.preventDefault(); to not get the refresh in the browser at this moment
// if current id not nul, don't dispatch a create post -> update it
//Paper: white background
// ...postData to not override everything in onChange -> spread the postData, then creator

export default EditProfile;