import React, { useEffect, useState } from "react";

import { PaginationItem } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getPosts, getPostsBySearch, getPostsByCategory } from '../../actions/posts';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledPagination from "./paginationStyles";

const theme = createTheme();
const PREFIX = 'Paginate';
const classes = {
    ul: `${PREFIX}-ul`,
    pageButton: `${PREFIX}-pageButton`
};


const Paginate = ({ page, updatePage, metricFilter, formatFilter, searchQuery, categoryQuery, trickUseEffectTrigger }) => {
    const { numberOfPages } = useSelector((state) => state.posts); // reducer that has access to the nmbr of pages
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));
    const [userFollowing, setUserFollowing] = useState({
        followedCircles: user?.result?.followedCircles, followedTags: user?.result?.followedTags
    });
    const userFollowingApi = JSON.stringify({ userFollowing })

    const handlePageClick = (newPage) => {
        updatePage(newPage);
    };

    // fetch the right posts for the right request
    useEffect(() => {
        if(page && !searchQuery && !categoryQuery) dispatch(getPosts(page, metricFilter, formatFilter, userFollowingApi));
        if(page && searchQuery) dispatch(getPostsBySearch(searchQuery, page, metricFilter, formatFilter));
        if(page && categoryQuery) dispatch(getPostsByCategory(categoryQuery, page, metricFilter, formatFilter));
    }, [page, metricFilter, formatFilter, searchQuery, categoryQuery, trickUseEffectTrigger, userFollowing]);

    return (
        <div>
        {( (numberOfPages > 1) && (
            <StyledPagination
            classes={{ ul: classes.ul }}
            count={numberOfPages}
            page={Number(page) || 1} // render page 1 if no page
            shape="rounded"
            //color="primary"
            sx={{marginBottom:'10px'}}
            renderItem={(item) => (
                <PaginationItem className={classes.pageButton} {...item} onClick={() => handlePageClick(item.page)} //to={`${location.pathname}?page=${item.page}`} 
                />
            )}
        />
        ) )}
        </div>

    );
};

export default Paginate;