import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';

const PREFIX = 'SideBar';

const classes = {
  appBar: `${PREFIX}-appBar`,
  categoryTitle: `${PREFIX}-categoryTitle`,
  categoryButton: `${PREFIX}-categoryButton`,
  profileLink: `${PREFIX}-profileLink`
};

const StyledSideBar = styled(Grid)((
  {
    theme
  }
  ) => ({
  [`& .${classes.appBar}`]: {
    //backgroundColor:'#151515', //fafafa
    borderRadius: 15,
    //justifyContent: 'space-between',
    //justifyContent: "flex-start",
    //padding: '8px',
    display: 'flex',
    //marginRight: '0%',
    flexDirection: 'column',
    alignItems: "flex-start",
    [theme.breakpoints.down('md')]: {
      alignItems: "center",
    },
  },

  [`& .${classes.categoryTitle}`]: {
    display:"flex",
    fontSize: '18px',
    marginLeft:'-2px',
    padding: '12px 0px 0px 7px',
    [theme.breakpoints.down('md')]: {
      padding: '8px 16px 0px 16px',
    },
  },

  [`& .${classes.categoryButton}`]: {
    color: '#7bacd7', //#94e0e0
    borderRadius: 15,
    marginBottom:'5px',
    textTransform: 'none',
    fontSize: '16px',
    "&:hover": {
      //boxShadow: "#000000",
      //background: "#F0EFEF"
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: "flex-start",
    },
  },

  [`& .${classes.profileLink}`]: {
    borderRadius: 15,
    padding: '5px 10px 5px 10px',
    background:'#212121', // 292929
    "&:hover": {
      //boxShadow: "#000000",
      background: "#313131" // 333333
    },
  },

}));

export default StyledSideBar;