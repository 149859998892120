import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

const PREFIX = 'PostDetails';

const classes = {
  media: `${PREFIX}-media`,
  mediaBook: `${PREFIX}-mediaBook`,
  card: `${PREFIX}-card`,
  section: `${PREFIX}-section`,
  details: `${PREFIX}-details`,
  moreOptions: `${PREFIX}-moreOptions`,
  categoryLink: `${PREFIX}-categoryLink`,
  description: `${PREFIX}-description`,
  cardActions: `${PREFIX}-cardActions`,
  cardButton: `${PREFIX}-cardButton`,
  imageSection: `${PREFIX}-imageSection`,
  recommendedPosts: `${PREFIX}-recommendedPosts`,
  loadingPaper: `${PREFIX}-loadingPaper`
};

const StyledPaper = styled(Paper)((
    {
      theme
    }
  ) => ({
    [`& .${classes.media}`]: {
      borderRadius: '15px',
      //objectFit: 'cover',
      //width: '100%',
      //maxHeight: '300px',
      //maxWidth: '600px',
      height: 'auto',
      width: '98%',
      [theme.breakpoints.down('md')]: {
        height: 'auto',
        width: '96%',
      },
    },

    [`& .${classes.mediaBook}`]: {
      height: '140px',
      //width: '100%',
      borderRadius: 8,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      backgroundBlendMode: 'darken',
      [theme.breakpoints.down('md')]: {
        height: '220px',
        //width: '100%',
      },
    },
  
    [`& .${classes.card}`]: {
      display: 'flex',
      width: '100%',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        flexDirection: 'column',
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '5px'
      },
    },
  
    [`& .${classes.section}`]: {
      borderRadius: '20px',
      //margin: '10px',
      flex: 1,
    },

    [`& .${classes.details}`]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems:'center',
      padding: '0px 0px 15px 0px',
    },
  
    [`& .${classes.imageSection}`]: {
      display: 'flex',
      justifyContent: 'center',
      padding: '5px 0px 15px 0px',
    },

    [`& .${classes.moreOptions}`]: {
      borderRadius: 15,
      display: 'flex',
      justifyContent: 'center',
      "&:hover": {
        boxShadow: "#000000",
        //background: "#F0EFEF"
      },
      minWidth:'35px',
      maxWidth:'35px',
    },

    [`& .${classes.categoryLink}`]: {
      borderRadius: 15,
      padding: '5px 10px 5px 10px',
      background:'rgba(35, 35, 35, 0.05)', // 292929
      "&:hover": {
        //boxShadow: "#000000",
        background: "rgba(35, 35, 35, 0.1)" // 333333
      },
    },

    [`& .${classes.description}`]: {
      padding: '0px 8px 0px 8px',
      fontSize: '16px',
      textAlign: 'justify'
    },

    [`& .${classes.cardActions}`]: {
      padding: '0px 0px 0px 0px',
      //margin: '20px 0px 25px 0px',
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('md')]: {
        margin: '15px 0px 20px 0px',
      },
      [theme.breakpoints.up('md')]: {
        margin: '20px 20% 25px 20%',
      },
    },

    [`& .${classes.cardButton}`]: {
      borderRadius: 15,
      textTransform: 'none',
      fontSize: '15px',
      fontWeight: 'light',
      color:'#679dc9',
      "&:hover": {
        //boxShadow: "#000000",
        //background: "#F0EFEF"
      },
    },
  
    [`& .${classes.recommendedPosts}`]: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
      },
    },
  
    [`&.${classes.loadingPaper}`]: {
      display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '20px', borderRadius: '15px', height: '39vh',
    }
  }));

  export default StyledPaper;