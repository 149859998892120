import { styled } from '@mui/material/styles';
import { Grow } from '@mui/material';

const PREFIX = 'UserProfile';

const classes = {
    profileHead: `${PREFIX}-profileHead`,
    pagination: `${PREFIX}-pagination`,
    gridContainer: `${PREFIX}-gridContainer`,
    profilePostsTitle: `${PREFIX}-profilePostsTitle`,
};

const StyledGrow = styled(Grow)((
    {
        theme
    }
) => ({
    [`& .${classes.profileHead}`]: {
      borderRadius: 15,
      marginBottom: '24px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '16px',
    },

    [`& .${classes.pagination}`]: {
      borderRadius: 15,
      marginTop: '24px',
      padding: '16px',
    },

    [`& .${classes.gridContainer}`]: {
      //[theme.breakpoints.down('xs', 'xl')]: {
        //flexDirection: 'column-reverse',
      //},
    },

    [`& .${classes.profilePostsTitle}`]: {
        padding: '0px 16px 10px 13px',
    },
  
}));

export default StyledGrow;