import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper, Container, Link, Grid, Chip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledSideBar from './explorePageStyles';

const theme = createTheme();
const PREFIX = 'ExplorePage';
const classes = {
  appBar: `${PREFIX}-appBar`,
  categoryTitle: `${PREFIX}-categoryTitle`,
  categoryButton: `${PREFIX}-categoryButton`
};

const categories = [
  {value: 'Technology'},
  {value: 'Startup'},
  {value: 'Psychology'},
  {value: 'Self Improvement'},
  {value: 'Design'},
  {value: 'Leadership'},
  {value: 'Society'},
  {value: 'Philosophy'},
  {value: 'Environment'},
  {value: 'Marketing'},
  {value: 'Business'},
  {value: 'Finance'},
  {value: 'Art'},
  {value: 'History'},
  {value: 'Geography'},
  {value: 'Economics'},
  {value: 'Sociology'},
  {value: 'Programming'},
  {value: 'Engineering'},
  {value: 'Mathematics'},
  {value: 'Biology'},
  {value: 'Physics'},
  {value: 'Health'},
];


const ExplorePage = ({  }) => {
  const history = useHistory();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  const displayCategory = (category) => {
    history.push(`/stories/category?category=${category}`);
  };

    return <Container component="main" maxWidth="sm" >
            <StyledSideBar position="standard" >
                <Paper className={classes.appBar} elevation={0} >
                <Typography className={classes.categoryTitle} sx={{ fontWeight: 'bold', marginBottom:'20px' }} component="h1" variant="h6">Explore circles</Typography>
                <Grid container justifyContent="center" >
                {categories.map((category) => (
                    <Chip
                    key={category.value}
                    label={category.value}
                    clickable
                    //color={profileData.followedCircles.includes(category.value) ? 'primary' : 'default'}
                    onClick={() => displayCategory(category.value)}
                    style={{ marginRight: '0.5rem', marginBottom: '0.5rem' }}
                    sx={{ fontSize: "16px", fontWeight:'light', color:'#ffffff', backgroundColor: '#182029', '&:hover': {backgroundColor: '#2c3d4e'}}} //292929 - 333333
                    />
                ))}
                </Grid>
                </Paper>
            </StyledSideBar>
        </Container>
};

export default ExplorePage;