import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, CircularProgress, Paper, Divider, Button, Menu, MenuItem, Grid, AppBar } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getPostsByUserList } from '../../actions/posts';
import Posts from '../Posts/Posts';
import SideBar from "../SideBar/SideBar";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrow from './userListStyles';

const theme = createTheme();
const PREFIX = 'UserProfile';

const classes = {
  profileHead: `${PREFIX}-profileHead`,
  pagination: `${PREFIX}-pagination`,
  gridContainer: `${PREFIX}-gridContainer`,
  profilePostsTitle: `${PREFIX}-profilePostsTitle`,
};


const UserList = () => {
    const { posts, isLoading } = useSelector((state) => state.posts);
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
      defaultMatches: true
    });

    useEffect(() => {
      dispatch(getPostsByUserList(userId));
    }, [userId]); //happens whenever the id of the user changes

    if(!user?.result?.name) {
      return (
          <Paper style={{padding: theme.spacing(2), borderRadius: 15}} elevation={0}>
              <Typography variant="h6" align="center">
                  Log in to get your own list.
              </Typography>
          </Paper>
      );
    };

    if (!posts.data) {
      return (
        <CircularProgress/>
      );
    };

    return (
      
      <StyledGrow in>
          <Grid className={classes.gridContainer} container justifyContent="space-between" alignItems="stretch" spacing={5} >
            <Grid item xs={12} sm={12} md={12} >
              <Typography className={classes.profilePostsTitle} variant="h6" elevation={1} sx={{ fontWeight: 'bold' }} >
                Saved
              </Typography>
              <Posts posts={posts.data} isLoading={isLoading} />
            </Grid>
          </Grid>
      </StyledGrow>
    );

};

export default UserList;