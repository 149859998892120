import React, { useState } from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, ButtonBase, Grid, Menu, MenuItem, Tooltip, Snackbar, IconButton, Dialog } from '@mui/material';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import BookmarkOutlinedIcon from '@mui/icons-material/BookmarkOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import FlagCircleOutlinedIcon from '@mui/icons-material/FlagCircleOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { ReactTinyLink } from 'react-tiny-link';
import useMediaQuery from '@mui/material/useMediaQuery';

import evotreeLogo from '../../../images/circletree_logo_v4.png';
import youtubeLogo from '../../../images/youtube_logo.PNG';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import MenuBookOutlinedIcon from '@mui/icons-material/MenuBookOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import { deletePost, likePost, downvotePost, addPostToUserList } from '../../../actions/posts';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledCard from './postStyles';

const theme = createTheme();
const PREFIX = 'Post';

const classes = {
    border: `${PREFIX}-border`,
    fullHeightCard: `${PREFIX}-fullHeightCard`,
    card: `${PREFIX}-card`,
    media: `${PREFIX}-media`,
    mediaBook: `${PREFIX}-mediaBook`,
    overlay: `${PREFIX}-overlay`,
    overlay2: `${PREFIX}-overlay2`,
    grid: `${PREFIX}-grid`,
    title: `${PREFIX}-title`,
    details: `${PREFIX}-details`,
    content: `${PREFIX}-content`,
    moreOptions: `${PREFIX}-moreOptions`,
    categoryLink: `${PREFIX}-categoryLink`,
    body: `${PREFIX}-body`,
    tags: `${PREFIX}-tags`,
    cardActions: `${PREFIX}-cardActions`,
    cardAction: `${PREFIX}-cardAction`,
    cardButton: `${PREFIX}-cardButton`
};

function getMostRecurrentElements(array) {
    // Create an object to track the frequency of each element
    const frequencyMap = {};
    // Count the frequency of each element in the array
    array.forEach((element) => {
      if (frequencyMap[element]) {
        frequencyMap[element]++;
      } else {
        frequencyMap[element] = 1;
      }
    });
    // Sort the unique elements based on their frequency in descending order
    const sortedElements = Object.keys(frequencyMap).sort(
      (a, b) => frequencyMap[b] - frequencyMap[a]
    );
    // Return the top 3 most recurrent elements
    return sortedElements.slice(0, 3);
  }
  

const Post = ({ post, setCurrentId }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('profile'));
    const [likes, setLikes] = useState(post?.likes); // to have the like button colored directly, not after several seconds (after having the like in the database), for better user experience
    const [downvotes, setDownvotes] = useState(post?.downvotes); // to have the like button colored directly, not after several seconds (after having the like in the database), for better user experience
    const [inList, setInList] = useState(post?.inList); // to have the save button colored directly
    const userId = user?.result?._id;
    const hasLikedPost = likes?.find((like) => like === userId);
    const hasDownvotedPost = downvotes?.find((downvote) => downvote === userId);
    const hasPostInList = inList?.find((id) => id === userId);
    const userInUploads = post.creator.includes(userId);
    const [showSnackbarCopyLink, setShowSnackbarCopyLink] = useState(false);

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true
      });

    // for menulist (options: edit or delete)
    const [anchor, setAnchor] = useState(null);

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
      };
    
    const closeMenu = () => {
      setAnchor(null);
    };

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
    setExpanded(!expanded);
    };

    // for image full screen
    const [openImage, setOpenImage] = useState(false);
    const [selectedImage, setSelectedImage] = useState('');
    const handleOpenImage = (image) => {
        setSelectedImage(image);
        setOpenImage(true);
    };
    const handleCloseImage = () => {
        setOpenImage(false);
    };

    // to have the downvote button colored directly, not after several seconds (after having the downvote in the database), for better user experience
    const handleDownvote = async () => {
        dispatch(downvotePost(post._id));

        if(hasDownvotedPost) {
            setDownvotes(post.downvotes.filter((id) => id !== userId));
        } else {
            setDownvotes([...post.downvotes, userId]);
        }
    };

    const Downvotes = () => {
        if (downvotes.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
            return downvotes.find((downvote) => downvote === userId)
                ? (
                <><ThumbDownIcon sx={{fontSize: isMobile ? '20px' : '22px'}} /></>
            ) : (
                <><ThumbDownOutlinedIcon sx={{fontSize: isMobile ? '20px' : '22px'}} /></>
            );
        }
    
        return <><ThumbDownOutlinedIcon sx={{fontSize: isMobile ? '20px' : '22px'}} /></>;
    };

    // to have the like button colored directly, not after several seconds (after having the like in the database), for better user experience
    const handleLike = async () => {
        dispatch(likePost(post._id));

        if(hasLikedPost) {
            setLikes(post.likes.filter((id) => id !== userId));
        } else {
            setLikes([...post.likes, userId]);
        }
    };

    const Likes = () => {
        if (likes.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
            return likes.find((like) => like === userId)
                ? (
                <><WbSunnyIcon sx={{fontSize: isMobile ? '20px' : '22px'}} />&nbsp;{likes.length}&nbsp;</>
            ) : (
                <><WbSunnyOutlinedIcon sx={{fontSize: isMobile ? '20px' : '22px'}} />&nbsp;{likes.length}&nbsp;</>
            );
        }
    
        return <><WbSunnyOutlinedIcon sx={{fontSize: isMobile ? '20px' : '22px'}} />&nbsp;</>;
    };

    const renderTooltip = () => {
        if (!user?.result) {
          return <Typography sx={{fontSize: 14}}>
                    Log in to use all the features
                </Typography>;
        }
        return null;
      };

    const handleEdit = () => {
        history.push({pathname:'/edit', data: post._id  });
    };

    const handleDelete = () => {
        dispatch(deletePost(post._id, history));
    };

    const handleReport = () => {
        dispatch(closeMenu());
    };

    const handleAddToList = async () => {
        dispatch(addPostToUserList(post._id));

        if(hasPostInList) {
            setInList(post.inList.filter((id) => id !== userId));
        } else {
            setInList([...post.inList, userId]);
        }
    };

    const AddToList = () => {
        if (inList.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
            return inList.find((id) => id === userId)
                ? (
                <><BookmarkOutlinedIcon sx={{fontSize: isMobile ? '20px' : '22px'}} /></>
            ) : (
                <><BookmarkBorderOutlinedIcon sx={{fontSize: isMobile ? '20px' : '22px'}} /></>
            );
        }
    
        return <><BookmarkBorderOutlinedIcon sx={{fontSize: isMobile ? '20px' : '22px'}} /></>;
    };
    
    const handleCopyLink = () => {
          navigator.clipboard.writeText(`https://www.circletree.io/stories/${post._id}`);
          setShowSnackbarCopyLink(true);
        };
    const handleCloseSnackbarCopyLink = () => {
        setShowSnackbarCopyLink(false);
      };

    const openPost = () => history.push(`/stories/${post._id}`);

    return (
        <StyledCard className={classes.card} raised elevation={0}>
            <div className={classes.details}>
                <Link to={`/profile/${post.creator}`} className={classes.categoryLink} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography sx={{ fontWeight: 'light', marginRight: '0px' }} variant="body2" alignItems="center">{post.name}</Typography>
                </Link>
                {/*<Link to={`/stories/category?category=${post.category}`} className={classes.categoryLink} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography sx={{ fontWeight: 'light' }} variant="body2">{post.category}</Typography>
                </Link>*/}
                <Typography sx={{ fontWeight: 'light' }} variant="body2" color="textSecondary">{Math.max(1, Math.round(post.message.length / 4 / 200))} min read</Typography>
                <Typography sx={{ fontWeight: 'light', marginRight: '0px' }} variant="body2" color="textSecondary" alignItems="center">{moment(post.createdAt).format("MMM DD")}</Typography>
                <Button className={classes.moreOptions} style={{color: 'grey'}} size="small" onClick={openMenu}>
                      <MoreHorizIcon fontSize="medium" />
                </Button>
                <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                    {(userInUploads) && (
                        <div>
                        {(post.type !== 'resource') && (
                        <MenuItem onClick={handleEdit}><EditOutlinedIcon/>&nbsp;Edit</MenuItem>
                        )}
                        <MenuItem onClick={handleDelete}><DeleteOutlinedIcon/>&nbsp;Delete</MenuItem>
                        </div>
                    )}
                    {(userId !== post?.creator) && (
                        <div>
                            <MenuItem onClick={handleReport}><FlagCircleOutlinedIcon/>&nbsp;Report</MenuItem>
                        </div>
                    )}
                </Menu>
            </div>
            
            
            <div>
                {(post.type === "post") && (
                    <Grid item className={classes.content} xs={12} sm={12} md={12} lg={12} >
                    <Grid item xs={12} sm={12} md={12} lg={12} >
                        <Link to={`/stories/${post._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        <Grid sx={{'&:hover': { '& .Post-title': { color: '#679dc9' }}}}>
                        <Typography className={classes.title}
                            sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical' }}
                            variant="body1" >{post.title}
                        </Typography>
                        </Grid>
                        </Link>
                        <Typography className={classes.body}
                            sx={{
                                fontWeight: 'light',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: expanded ? 'none' : '4',
                              }}
                            variant="body1" component="p">
                                {expanded ? post.message.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br />
                                </React.Fragment>
                                )) : (isMobile ? post.message.substring(0, 150) + (post.message.length > 149 ? '...' : '') : post.message.substring(0, 300) + (post.message.length > 299 ? '...' : '')) }
                        </Typography>
                        
                        { ((isMobile && post.message.length > 149) || (!isMobile && post.message.length > 299)) && (
                        <Grid container display="flex" flexDirection="row" justifyContent="flex-end" sx={{ margin: '-33px 0px 0px 0px' }}>
                        <IconButton
                            size="small"
                            onClick={(event) => {
                            event.stopPropagation(); // Stops the click event from reaching the link
                            handleExpandClick();
                            }}
                            aria-expanded={expanded}
                            aria-label="show more"
                        >
                            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                        </Grid>
                        )}
                        </Grid>
                        {post.image && (
                        <Grid container item xs={12} direction="column" alignItems="center">
                            <img 
                                className={classes.media}
                                src={post.image} 
                                onClick={() => handleOpenImage(post.image)}
                                style={{ cursor: 'pointer' }}
                            />
                        </Grid>
                        )}
                    </Grid>
                )}
            </div>

            <Dialog
                open={openImage}
                onClose={handleCloseImage}
                maxWidth="md"
                PaperProps={{
                    style: isMobile ? { height: 'auto', width: '90%', margin: '0', borderRadius: 8, backgroundColor: 'transparent' } : { height: '80%', width: 'auto', borderRadius: 8, backgroundColor: 'transparent' }
                }}
                BackdropProps={{
                    style: { backgroundColor: 'rgba(0, 0, 0, 0.95)' }
                }}
            >
                <IconButton 
                    onClick={handleCloseImage} 
                    style={{ color: 'lightGrey', position: 'fixed', right: 10, top: 10 }}
                >
                    <CloseIcon />
                </IconButton>
                <img src={selectedImage}
                    style={{ maxHeight: '100%', 
                    width: 'auto', 
                    maxWidth: '100%', 
                    objectFit: 'contain', 
                    display: 'block',
                    margin: 'auto',
                    borderRadius: 8 }}
                />
            </Dialog>

            

            
            {!user?.result && (
            <Tooltip title={renderTooltip()}>

            <CardActions className={classes.cardActions}>
                <Button className={classes.cardButton} size="small"
                sx={{ fontWeight: 'light', color:'grey' }}
                disabled={!user?.result} onClick={handleLike}>
                    <Likes />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ fontWeight: 'light', color:'grey' }}
                disabled={!user?.result} onClick={handleAddToList}>
                    <AddToList />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ fontWeight: 'light', color:'grey' }}
                disabled={!user?.result} onClick={handleDownvote}>
                    <Downvotes />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleCopyLink}>
                    <ShareOutlinedIcon  sx={{fontSize: isMobile ? '20px' : '22px'}}/>
                </Button>
                <Snackbar
                    open={showSnackbarCopyLink}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbarCopyLink}
                    message="Link copied to clipboard"
                />
            </CardActions>
            </Tooltip>
            )}
            {user?.result && (
            <CardActions className={classes.cardActions}>
                <Button className={classes.cardButton} size="small"
                sx={{ borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleLike}>
                    <Likes />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleAddToList}>
                    <AddToList />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleDownvote}>
                    <Downvotes />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ borderColor: 'white', fontWeight: 'light' }}
                disabled={!user?.result} onClick={handleCopyLink}>
                    <ShareOutlinedIcon sx={{fontSize: isMobile ? '20px' : '22px'}}/>
                </Button>
                <Snackbar
                    open={showSnackbarCopyLink}
                    autoHideDuration={2000}
                    onClose={handleCloseSnackbarCopyLink}
                    message="Link copied to clipboard"
                    anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                />
            </CardActions>
            )}
            
        </StyledCard>
    );
}

//&nbsp; = space
// possibility to add like - likes if numerous likes: check tutorial 04:46:22 & github part 3
// for delete and edit features, check if the user is the creator before allowing them
// button base general button of the post/card

export default Post;