import React, { useState } from "react";

import Form from './Form/Form';

const EditPostPage = (data) => {    
    const [currentId, setCurrentId] = useState(data.history.location.data);

    return (
        <Form currentId={currentId} setCurrentId={setCurrentId} />
    )
};

export default EditPostPage;