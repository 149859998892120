import React, { useState } from "react";

import Form from './Form/Form';

const CreatePostPage = () => {
    const[currentId, setCurrentId] = useState(null);

    return (
        <Form currentId={currentId} setCurrentId={setCurrentId} />
    )
};

export default CreatePostPage;