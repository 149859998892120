import React, { useEffect } from 'react';
import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, CircularProgress, Paper, Divider, Button, Menu, MenuItem, Grid, AppBar, Avatar, Tooltip } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getUserProfile } from '../../actions/profile';
import Posts from '../Posts/Posts';
import SideBar from "../SideBar/SideBar";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledGrow from './userProfileStyles';

const theme = createTheme();
const PREFIX = 'UserProfile';

const classes = {
  profileHead: `${PREFIX}-profileHead`,
  pagination: `${PREFIX}-pagination`,
  gridContainer: `${PREFIX}-gridContainer`,
  profilePostsTitle: `${PREFIX}-profilePostsTitle`,
  editButton: `${PREFIX}-editButton`,
};


const UserProfile = () => {
    const { profile } = useSelector((state) => state);
    const posts = profile.posts
    const isLoading = profile.isLoading
    const dispatch = useDispatch();
    const history = useHistory();
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;
    const profileId = profile?.profile?._id;
    const { id } = useParams();
    const contributionLabel = profile?.profile?.contributions > 1 ? 'contribution points' : 'contribution point'

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
      defaultMatches: true
    });

    // get the data and posts of a single user
    useEffect(() => {
      dispatch(getUserProfile(id));
    }, [id]); //happens whenever the id of the user changes

    const handleEditProfile = () => {
      history.push({pathname:'editprofile' });
    };

    if (!profile.profile) {
      return (
        <Grid container justifyContent="center" marginTop="10px">
        <Typography variant="h6">No profile with this id.</Typography>
        </Grid>
      );
    };

    return (
      
      <StyledGrow in>
          <Grid className={classes.gridContainer} container justifyContent="space-between" alignItems="stretch" spacing={5} >
            <Grid item xs={12} sm={12} md={12} >
              <Paper className={classes.profileHead} sx={{position: 'relative'}} elevation={0}>
                {/*<Typography variant="h6" elevation={1} sx={{ fontWeight: 'bold' }} >{profile.profile.name}</Typography>/>*/}
                {/*<Avatar src={profile.profile.profilePicture} className={classes.avatar} />*/}
                <div>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{profile.profile.name}</Typography>
                  <Typography variant="body1" marginTop='4px'>{profile.profile.bio}</Typography>
                  {/*<Typography variant="body2" color='lightGrey' marginTop='8px'>{profile.profile.contributions} {contributionLabel}</Typography>*/}
                  <Typography variant="body2" color='textSecondary' marginTop='8px'>Y followers</Typography>
                </div>
                {(userId !== profileId) && (
                  <Button
                  //onClick={handleEditProfile}
                  sx={{ textTransform: 'none', color: 'white', backgroundColor: 'black', borderColor: 'black', borderRadius: '20px', marginTop: '10px' }}
                  variant="contained"
                  size="medium"
                  type="submit"
                  disableElevation>
                    Follow
                  </Button>
                  )}

                {(userId == profileId) && (
                <Tooltip title={<Typography>Edit profile</Typography>} arrow>
                <Button className={classes.editButton} sx={{position: 'absolute', top: theme.spacing(1.5), right: theme.spacing(1.5)}}
                onClick={handleEditProfile}><EditOutlinedIcon sx={{fontSize:24}}/>
                </Button>
                </Tooltip>
                )}
              </Paper>

              <Typography className={classes.profilePostsTitle} variant="h6" elevation={1} sx={{ fontWeight: 'bold' }} >
                Last uploaded stories
              </Typography>
              <Posts posts={posts} isLoading={isLoading} />
            </Grid>
          </Grid>
      </StyledGrow>
    );

};

export default UserProfile;