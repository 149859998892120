import { FETCH_POST, FETCH_ALL, FETCH_BY_SEARCH, FETCH_BY_CATEGORY, FETCH_BY_USER_LIST, CREATE, UPDATE, DELETE, COMMENT, FETCH_TOP_CONTRIBUTORS, START_LOADING, END_LOADING } from '../constants/actionTypes';


export default (state = { isLoading: true, posts: [] }, action) => {
    switch (action.type) {
        case START_LOADING:
            return { ...state, isLoading: true }
        case END_LOADING:
            return { ...state, isLoading: false }
        case FETCH_ALL:
            return {
                ...state, // spread the state when we work with objects
                posts: action.payload.data, // then we want to get our posts - in case i need it later:  posts: [...state.posts, ...action.payload.data]
                currentPage: action.payload.currentPage,
                numberOfPages: action.payload.numberOfPages,
            };
        case FETCH_POST:
            return { ...state, post: action.payload }; // or with payload.post
        case FETCH_BY_SEARCH:
            return {
                ...state, // spread the state when we work with objects
                posts: action.payload.data, // then we want to get our posts
                currentPage: action.payload.currentPage,
                numberOfPages: action.payload.numberOfPages,
            };
        case FETCH_BY_CATEGORY:
            return {
                ...state, // spread the state when we work with objects
                posts: action.payload.data, // then we want to get our posts
                currentPage: action.payload.currentPage,
                numberOfPages: action.payload.numberOfPages,
            };
        case FETCH_BY_USER_LIST:
            return { ...state, posts: action.payload };;
        case CREATE: 
            return { ...state, posts: [...state.posts, action.payload]};
        case UPDATE:
            return { ...state, posts: state.posts.map((post) => post._id === action.payload._id ? action.payload : post)};
        case DELETE:
            return { ...state, posts: state.posts.filter((post) => post._id !== action.payload)};
        // change the post that just received a comment and return all the other posts normally
        case COMMENT:
            return {
              ...state,
              posts: state.posts.map((post) => {
                if (post._id === +action.payload._id) { //keep the '+' ?
                  return action.payload;
                }
                return post;
              }),
            };
        case FETCH_TOP_CONTRIBUTORS:
            return { ...state, contributors: action.payload }; // or with payload.post
        default:
            return state;
    }
}

// loading state with redux confusing, but will help us in the long run by maintaining one state of truth
// having an object that now contains the isLoading state as well as the posts state so that's why we have to turn this into an object spread the entire state and then finally only return the posts

//output of a map is an array