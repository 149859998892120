import React, { useState, useRef } from 'react';
import { Typography, TextField, Button, Divider, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

import { commentPost, updatePost } from '../../../actions/posts';

import Comment from './Comment/Comment';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import Root from './commentSectionStyles';

const theme = createTheme();
const PREFIX = 'CommentSection';

const classes = {
    submitCommentButton: `${PREFIX}-submitCommentButton`,
    commentField: `${PREFIX}-commentField`,
    commentsOuterContainer: `${PREFIX}-commentsOuterContainer`,
    commentsInnerContainer: `${PREFIX}-commentsInnerContainer`
};

const CommentSection = ({ post }) => {

    const dispatch = useDispatch();
    const commentsRef = useRef();
    const [comments, setComments] = useState(post?.comments);
    const [commentData, setCommentData] = useState({ message: '' });
    const [commentToEdit, setCommentToEdit] = useState(null); // commentToEdit null to create a comment, id to edit an existing comment
    const user = JSON.parse(localStorage.getItem('profile'));

    const handleComment = async () => {        
        if (commentToEdit === null) {
            const newComments = await dispatch(commentPost({ ...commentData, name: user?.result?.name }, post._id)); //2 params: comment, and on which post
            setCommentData({ message: '' });
            setComments(newComments);
        } else {
            post.comments.filter((comment) => comment._id === commentToEdit)[0].message = commentData.message
            dispatch(updatePost(post._id, post))
            setCommentToEdit(null)
            setCommentData({ message: '' });
        }
        //commentsRef.current.scrollIntoView({ behavior: 'smooth' }); //scroll (slowly bc smooth) to the new comment to see it directly
    };

    return (
        <Root>
        <div>
            <div className={classes.commentsOuterContainer}>
                <div className={classes.commentsInnerContainer}>
                    {comments.length !== 0 && (
                        <Typography gutterBottom variant="h6" fontSize="18px" fontWeight="bold">Comments</Typography>
                    )}
                    {comments.length === 0 && user?.result?.name && (
                        <Typography gutterBottom variant="h6" fontSize="18px" fontWeight="bold">Be the first to comment</Typography>
                    )}
                    
                    {user?.result?.name && (
                    <Grid display='flex' flexDirection='column' alignItems='flex-end' style={{margin: '10px 10px 0px 0px'}}>
                        {/*<Typography gutterBottom variant="h6">Write a comment</Typography>*/}
                        <TextField className={classes.commentField}
                            fullWidth
                            minRows={1}
                            variant="standard"
                            label={commentData.message ? " " : "Add a comment"}
                            InputLabelProps={{shrink: false}}
                            multiline
                            inputProps={{maxLength: 400}}
                            value={commentData.message}
                            onChange={(e) => setCommentData({...commentData, message: e.target.value})}
                        />
                        <Button className={classes.submitCommentButton} style={{ margin: '10px 0px 10px 0px' }} sx={{ color: 'white' }} disabled={!commentData.message} variant="contained" size="small" disableElevation onClick={handleComment}>
                            Comment
                        </Button>
                    </Grid>
                )}
                    <Grid container alignItems="stretch" spacing={3}>
                        {comments?.map((comment, i) => (
                            <Grid key={i} item xs={12} sm={12} md={12} lg={12}>
                                <Comment post={post} comment={comment} comments={comments} setComments={setComments} setCommentData={setCommentData} setCommentToEdit={setCommentToEdit} ></Comment>
                            </Grid>
                        ))}
                        
                    </Grid>
                    <div ref={commentsRef} />
                </div>
                
                
                {!user?.result?.name && (
                    <Typography gutterBottom variant="h6" fontSize="18px" marginTop="40px">Log in to comment</Typography>
                )}
            </div>
        </div>
        </Root>
    );
};

// <strong>{c.split(': ')[0]}</strong> - {c.split(':')[1]} to differenciate the 2 parts of the comment and put in bold the username
// rows because comment can be long so we can put a big space

export default CommentSection;
