import React, { useState } from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, ButtonBase, Grid, Menu, MenuItem } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import moment from 'moment';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { deleteComment, likeComment, downvoteComment } from '../../../../actions/posts';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledCard from './commentStyles';

const theme = createTheme();
const PREFIX = 'Comment';

const classes = {
    border: `${PREFIX}-border`,
    fullHeightCard: `${PREFIX}-fullHeightCard`,
    card: `${PREFIX}-card`,
    media: `${PREFIX}-media`,
    overlay: `${PREFIX}-overlay`,
    overlay2: `${PREFIX}-overlay2`,
    grid: `${PREFIX}-grid`,
    title: `${PREFIX}-title`,
    details: `${PREFIX}-details`,
    content: `${PREFIX}-content`,
    moreOptions: `${PREFIX}-moreOptions`,
    profileLink: `${PREFIX}-profileLink`,
    body: `${PREFIX}-body`,
    cardActions: `${PREFIX}-cardActions`,
    cardAction: `${PREFIX}-cardAction`,
    cardButton: `${PREFIX}-cardButton`
};

const Comment = ({ post, comment, comments, setComments, setCommentData, setCommentToEdit }) => {
    const dispatch = useDispatch();
    const user = JSON.parse(localStorage.getItem('profile'));
    const userId = user?.result?._id;
    const [likes, setLikes] = useState(comment?.likes); // to have the like button colored directly, not after several seconds (after having the like in the database), for better user experience
    const [downvotes, setDownvotes] = useState(comment?.downvotes);
    const hasLikedComment = likes?.find((like) => like === userId);
    const hasDownvotedComment = downvotes?.find((downvote) => downvote === userId);

    // for menulist (options: edit or delete)
    const [anchor, setAnchor] = useState(null);

    const openMenu = (event) => {
        setAnchor(event.currentTarget);
      };
    
      const closeMenu = () => {
      setAnchor(null);
    };

    // to have the like button colored directly, not after several seconds (after having the like in the database), for better user experience
    const handleLike = async () => {
        dispatch(likeComment(post._id, comment._id));

        if(hasLikedComment) {
            setLikes(likes.filter((id) => id !== userId));
        } else {
            setLikes([...likes, userId]);
        }
    };

    const Likes = () => {
        if (likes.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
            return likes.find((like) => like === userId)
                ? (
                <><WbSunnyIcon fontSize="small" />&nbsp;{likes.length}&nbsp;</>
            ) : (
                <><WbSunnyOutlinedIcon fontSize="small" />&nbsp;{likes.length}&nbsp;</>
            );
        };
        
        return <><WbSunnyOutlinedIcon fontSize="small" />&nbsp;</>;
    };

    const handleDownvote = async () => {
        dispatch(downvoteComment(post._id, comment._id));

        if(hasDownvotedComment) {
            setDownvotes(downvotes.filter((id) => id !== userId));
        } else {
            setDownvotes([...downvotes, userId]);
        }
    };

    const Downvotes = () => {
        if (downvotes?.length > 0) { // if no like : outlind button, if likes : different possibilites with the number
            return downvotes.find((downvote) => downvote === userId)
                ? (
                <><ThumbDownIcon fontSize="small" /></>
            ) : (
                <><ThumbDownOutlinedIcon fontSize="small" /></>
            );
        };
        
        return <><ThumbDownOutlinedIcon fontSize="small" /></>;
    };

    const handleEdit = () => {
        setCommentData({ message: comment.message })
        setCommentToEdit(comment._id)
        closeMenu()
    }

    const handleDelete = () => {
        const deletedCommentId = comment._id
        dispatch(deleteComment(post._id, deletedCommentId));
        setComments(comments.filter((comment) => comment._id !== deletedCommentId));
    }

    return (
        <StyledCard className={classes.card} elevation={0}>
            <div className={classes.details}>
                <Link to={`/profile/${comment.creator}`} className={classes.profileLink} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Typography sx={{ fontWeight: 'light' }} variant="body2">{comment.name}</Typography>
                </Link>
                <Typography sx={{ fontWeight: 'light' }} variant="body2" color="textSecondary">{moment(comment.createdAt).fromNow()}</Typography>
                <Button className={classes.moreOptions} style={{color: 'grey'}} size="small"  onClick={openMenu}>
                      <MoreHorizIcon fontSize="medium" />
                </Button>
                <Menu open={Boolean(anchor)} anchorEl={anchor} onClose={closeMenu} keepMounted >
                    {(user?.result?.googleId === comment?.creator || user?.result?._id === comment?.creator) && (
                        <div>
                        <MenuItem onClick={handleEdit}><EditOutlinedIcon/>&nbsp;Edit</MenuItem>
                        <MenuItem onClick={handleDelete}><DeleteOutlinedIcon/>&nbsp;Delete</MenuItem>
                        </div>
                    )}
                </Menu>
            </div>
            <div className={classes.cardAction} >
                <Grid item xs={12} sm={12} md={12} lg={12} >
                    <Typography className={classes.body} sx={{ fontWeight: 'light' }} variant="body1" component="p">{comment.message}</Typography>
                </Grid>
            </div>

            <CardActions className={classes.cardActions}>
                <Button className={classes.cardButton} size="small"
                sx={{ borderColor: 'white' }}
                disabled={!user?.result} onClick={handleLike}>
                    <Likes />
                </Button>
                <Button className={classes.cardButton} size="small"
                sx={{ borderColor: 'white' }}
                disabled={!user?.result} onClick={handleDownvote}>
                    <Downvotes />
                </Button>
            </CardActions>

        </StyledCard>
    );
};


export default Comment;