import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Grid, Paper, Popper, TextField, Chip, InputAdornment } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { addTags } from '../../../actions/posts';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '10px', //theme.spacing(1),
    //backgroundColor: theme.palette.background.paper,
    width: 300,
  },
  parentContainer: {
    position: 'relative',
  },
  suggestTagsButton: {
    borderRadius: 40,
    textTransform: 'none',
    fontSize: '14px',
    color: 'lightGrey',
    backgroundColor: '#111111',
    "&:hover": {
      boxShadow: "#000000",
      background: "#181818"
    },
  },
  submitButton :{
    margin: '10px 0px 10px',
    borderRadius: 40,
    textTransform: 'none',
    fontSize: '16px',
    color: "black",
    backgroundColor: '#ffffff',
    "&:hover": {
      boxShadow: "#000000",
      background: "#e9e9e9"
    },
    }
}));

const SuggestTagsPopper = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { id } = useParams();

  const handleClick = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    dispatch(addTags(id, tags));
    handleClose();
  };

  //for tag system
  const [tags, setTags] = useState([]);
  const [tagInput, setTagInput] = useState('');
  const handleTagInputChange = (event) => {
      setTagInput(event.target.value);
  };
  const handleTagInputKeyDown = (event) => {
      if (event.key === 'Enter') {
      event.preventDefault();
      addTag();
      }
  };
  const addTag = () => {
      if (tagInput.trim() !== '') {
      setTags([...tags, tagInput.trim()]);
      setTagInput('');
      }
  };
  const removeTag = (index) => {
      const updatedTags = [...tags];
      updatedTags.splice(index, 1);
      setTags(updatedTags);
  };

  return (
    <div className={classes.parentContainer}>
      <Button className={classes.suggestTagsButton} ref={anchorRef} onClick={handleClick}>
        {open ? 'Close' : 'Suggest tags'}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        //transition
        //disablePortal
      >
        {({ TransitionProps }) => (
          <Paper
            elevation={3}
            className={classes.container}
            {...TransitionProps}
          >
            <Grid container direction="column" alignItems="center">
              <Grid item width='250px'>
              <TextField
                    label="Tags"
                    helperText={`Click "Enter" to add`}
                    value={tagInput}
                    onChange={handleTagInputChange}
                    onKeyDown={handleTagInputKeyDown}
                    fullWidth
                    variant="standard"
                    InputProps={{
                        startAdornment: tags.length > 0 && (
                          <InputAdornment position="start">
                            {tags.map((tag, index) => (
                                <Chip
                                    key={index}
                                    label={tag}
                                    onDelete={() => removeTag(index)}
                                    //variant="outlined"
                                    sx={{margin:'0px 0px 2px 5px', height: '28px'}}
                                />
                            ))}
                          </InputAdornment>
                        ),
                    }}
                />
              </Grid>
              <Grid item>
                <Button className={classes.submitButton} variant="contained" onClick={handleSubmit} size="small">
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Paper>
        )}
      </Popper>
    </div>
  );
};

export default SuggestTagsPopper;