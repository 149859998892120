import React, { useState, useEffect } from 'react';
import { TextField, Button, Typography, Paper, Container, Grid, Chip } from '@mui/material';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { getTopContributors } from '../../actions/posts';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import StyledSideBar from './contributorsRankingStyles';

const theme = createTheme();
const PREFIX = 'ContributorsRanking';
const classes = {
  appBar: `${PREFIX}-appBar`,
  categoryTitle: `${PREFIX}-categoryTitle`,
  categoryButton: `${PREFIX}-categoryButton`,
  profileLink: `${PREFIX}-profileLink`
};

const ContributorsRanking = ({  }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { contributors } = useSelector((state) => state.posts);
  const topContributors = contributors?.topContributors

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true
  });

  useEffect(() => {
    dispatch(getTopContributors());
  }, []);

    return <Container component="main" maxWidth="xs" >
            <StyledSideBar position="standard" >
                <Paper className={classes.appBar} elevation={0} >
                <Typography className={classes.categoryTitle} sx={{ fontWeight: 'bold', marginBottom:'20px' }} component="h1" variant="h6">Top contributors</Typography>
                <Grid container display="flex" flexDirection="column" justifyContent="center" >
                {topContributors?.map((contributor) => (
                    <Grid key={contributor._id} display="flex" flexDirection="row" alignItems="center" marginBottom="15px" >
                        <Typography sx={{ fontWeight: 'light' }} variant="body2">{topContributors?.indexOf(contributor) + 1} -&nbsp;&nbsp;</Typography>
                        <Link to={`/profile/${contributor._id}`} className={classes.profileLink} style={{ textDecoration: 'none', color: 'inherit' }}>
                            <Typography sx={{ fontSize: '16px' }} variant="body2">{contributor.name}</Typography>
                        </Link>
                        <Typography sx={{ fontWeight: 'light' }} variant="body2">&nbsp;&nbsp;{contributor.contributions} points</Typography>
                    </Grid>
                ))}
                </Grid>
                </Paper>
            </StyledSideBar>
        </Container>
};

export default ContributorsRanking;